var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-10"},[_c('a-card',{staticClass:"cus-card exam-time-card no-border",attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('div',{staticClass:"section-header dec d-flex align-center justify-between flex-wrap"},[_c('h2',{staticClass:"base-title fs-18"},[_vm._v("我的成绩")]),_c('p',{staticClass:"fs-14 color-gray link"})])]),_c('a-row',{attrs:{"gutter":34}},[_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('progress-bar',{attrs:{"options":{
              theme: '#4071fb',
              size: '64px',
              title: '课程',
              url: 'process-icon-kc',
              denom: _vm.gradeInfo.courseCount || 0, // 分母
              num: _vm.gradeInfo.passCount || 0 // 分子
            }}})],1),_c('a-col',{attrs:{"md":{ span: 12 },"xs":{ span: 24 }}},[_c('progress-bar',{attrs:{"options":{
              theme: '#fc9038',
              title: '学分',
              url: 'process-icon-xf',
              denom: _vm.gradeInfo.courseCredit || 0,
              num: _vm.gradeInfo.obtainCredit || 0,
              size: '64px'
            }}})],1)],1)],2)],1),_c('div',{staticClass:"mb-10"},[_c('a-card',{staticClass:"cus-card exam-time-card no-border",attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('div',{staticClass:"section-header dec d-flex align-center justify-between flex-wrap"},[_c('h2',{staticClass:"base-title fs-18"},[_vm._v("成绩详情")]),_c('p',{staticClass:"fs-14 color-gray link"})])]),_c('a-row',{staticClass:"mb-10"},[_c('a-col',{attrs:{"md":{ span: 8 },"xs":{ span: 24 }}},[_c('a-select',{staticClass:"cus-select",attrs:{"placeholder":"选择类别"},on:{"select":_vm.termChange},model:{value:(_vm.termActiveKey),callback:function ($$v) {_vm.termActiveKey=$$v},expression:"termActiveKey"}},_vm._l((_vm.termList),function(term){return _c('a-select-option',{key:term.termId,attrs:{"value":term.termId}},[_vm._v(" "+_vm._s(term.termName)+" ")])}),1)],1)],1),_c('div',{staticClass:"mb-10"},[_c('my-grade-detail-table',{attrs:{"dataSource":_vm.courseInfo.selectCourses}})],1)],2)],1),_c('div',{staticClass:"mb-10"},[_c('a-card',{staticClass:"cus-card exam-time-card no-border",attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('div',{staticClass:"section-header dec d-flex align-center justify-between flex-wrap"},[_c('h2',{staticClass:"base-title fs-18"},[_vm._v("学分详情")]),_c('p',{staticClass:"fs-14 color-gray link"})])]),_c('p',{staticClass:"required color-red pl-10 pb-10 fs-12"},[_vm._v(" 以下所有课程模块都满足毕业条件之后，才可以申请毕业 ")]),_c('div',{staticClass:"mb-10"},[_c('my-grade-score-table',{attrs:{"dataSource":_vm.creditInfo}})],1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }