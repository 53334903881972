<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 11:49:02
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:53:59
 * @FilePath: \crm-education\src\views\student\my_grade\c\MyGradeScoreTable.vue
-->
<template>
  <a-table
    class="cus-table fs-14"
    bordered
    :data-source="dataSource"
    :columns="columns"
    :pagination="false"
    rowKey="name1"
  ></a-table>
</template>

<script>
export default {
  name: 'my_grade_score_table',
  props: {
    dataSource: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        {
          title: '课程模块',
          align: 'center',
          dataIndex: 'moduleName'
        },
        // {
        //   title: '模块总学分',
        //   align: 'center',
        //   dataIndex: 'allCredit'
        // },
        {
          title: '模块最低毕业学分',
          align: 'center',
          dataIndex: 'moduleLowestCredit'
        },
        {
          title: '模块中央电大考试最低学分',
          align: 'center',
          dataIndex: 'moduleCenterLowestCredit'
        },
        {
          title: '已获学分',
          align: 'center',
          dataIndex: 'gainCredit'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'state'
        }
      ]
    }
  }
}
</script>
