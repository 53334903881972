<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 11:16:04
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 12:02:34
 * @FilePath: \crm-education\src\views\student\my_grade\index.vue
-->
<template>
  <div>
    <div class="mb-10">
      <a-card class="cus-card exam-time-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">我的成绩</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
        </template>
        <a-row :gutter="34">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#4071fb',
                size: '64px',
                title: '课程',
                url: 'process-icon-kc',
                denom: gradeInfo.courseCount || 0, // 分母
                num: gradeInfo.passCount || 0 // 分子
              }"
            ></progress-bar>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <progress-bar
              :options="{
                theme: '#fc9038',
                title: '学分',
                url: 'process-icon-xf',
                denom: gradeInfo.courseCredit || 0,
                num: gradeInfo.obtainCredit || 0,
                size: '64px'
              }"
            ></progress-bar>
          </a-col>
        </a-row>
      </a-card>
    </div>
    <div class="mb-10">
      <a-card class="cus-card exam-time-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">成绩详情</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
        </template>
        <a-row class="mb-10">
          <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
            <a-select class="cus-select" v-model="termActiveKey" placeholder="选择类别" @select="termChange">
              <a-select-option v-for="term in termList" :key="term.termId" :value="term.termId">
                {{ term.termName }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <div class="mb-10">
          <my-grade-detail-table :dataSource="courseInfo.selectCourses"></my-grade-detail-table>
        </div>
      </a-card>
    </div>
    <div class="mb-10">
      <a-card class="cus-card exam-time-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">学分详情</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
        </template>
        <p class="required color-red pl-10 pb-10 fs-12">
          以下所有课程模块都满足毕业条件之后，才可以申请毕业
        </p>
        <div class="mb-10">
          <my-grade-score-table :dataSource="creditInfo"></my-grade-score-table>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
import progressBar from '@/components/business/progressbar'
import MyGradeDetailTable from './c/MyGradeDetailTable'
import MyGradeScoreTable from './c/MyGradeScoreTable'
import api from '@/api'

export default {
  name: 'student_my_grade',
  components: {
    progressBar,
    MyGradeDetailTable,
    MyGradeScoreTable
  },
  data() {
    return {
      // studId: helper.getUserInfo('userId'),
      gradeInfo: {},
      termList: [],
      termActiveKey: '',
      courseInfo: [],
      creditInfo: []
    }
  },
  mounted() {
    this.initTermDicts().then(() => {
      this.init()
    })
  },
  methods: {
    async init() {
      this.initGrade()
      this.initCredit()
      this.initCourse()
    },
    refresh() {
      this.initCredit()
      this.initCourse()
    },
    async initGrade() {
      let res = await api.student.getGrade()
      if (res.code === 200) {
        this.gradeInfo = res.data
      }
    },
    async initTermDicts() {
      let termRes = await api.student.getTerm()
      if (termRes.code === 200 && termRes.data.length > 0) {
        this.termList = termRes.data
        let filterArray = termRes.data.filter(item => item.currentTeaching === 'Y');
        if(filterArray.length!=null && filterArray.length>0){
          this.termActiveKey = filterArray[0].termId;
        }else{
          this.termActiveKey = termRes.data[termRes.data.length-1].termId;
        }
      }
    },
    async initCourse() {
      const data = {
        termId: this.termActiveKey
      }
      let res = await api.student.getCourse(data)
      if (res.code === 200) {
        this.courseInfo = res.data
      }
    },
    async initCredit() {
      let res = await api.student.getCredit()
      if (res.code === 200) {
        this.creditInfo = res.data
      }
    },
    termChange() {
      this.refresh()
    }
  }
}
</script>
