<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 11:34:25
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:53:15
 * @FilePath: \crm-education\src\views\student\my_grade\c\MyGradeDetailTable.vue
-->
<template>
  <a-table
    class="cus-table fs-14"
    bordered
    :data-source="dataSource"
    :columns="columns"
    :pagination="false"
    rowKey="status"
  ></a-table>
</template>

<script>
export default {
  name: 'my_grade_detail_table',
  props: {
    dataSource: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        {
          title: '课程模块',
          align: 'center',
          dataIndex: 'moduleName'
        },
        {
          title: '课程信息',
          align: 'center',
          dataIndex: 'courseName'
        },
        {
          title: '学习成绩',
          align: 'center',
          dataIndex: 'learnScore'
        },
        {
          title: '考试成绩',
          align: 'center',
          dataIndex: 'examScore'
        },
        {
          title: '总成绩',
          align: 'center',
          dataIndex: 'allScore'
        },
        {
          title: '课程状态',
          align: 'center',
          customRender: (value, row, index) => {
            switch (Number(row.learnState)) {
              case 1:
                return (
                  <div class="grade-status no-study color-gray">未学习</div>
                )
              case 2:
                return <div class="grade-status studing">学习中</div>
              case 3:
                return <div class="grade-status sign color-gray">待登记</div>
              case 4:
                return <div class="grade-status-icon pass">已通过</div>
              case 5:
                return <div class="grade-status-icon no-pass">未通过</div>
              default:
                return ''
            }
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.grade-status-icon {
  display: inline-block;
  width: 64px;
  height: 58px;
  &.pass {
    background: url('../../../../assets/img/my-grade-pass.png') no-repeat center
      center;
  }
  &.no-pass {
    background: url('../../../../assets/img/my-grade-nopass.png') no-repeat
      center center;
  }
}
</style>
